import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext, createSelector } from "@ngxs/store";
import { LatLngBoundsLiteral } from "leaflet";
import { LAYER_SETTINGS } from "../config/initial-layers";
import { LayerSettings } from "../interfaces/layer-settings.interface";
import { MapModes } from "../interfaces/map-modes.enum";
import { DisableMapLayer, EnableMapLayer, MapStateModel, PatchMapState, SetHighlight, SetMeasureSettings, ToggleMapLayer, UpdateMapBounds, UpdateMapCenter, UpdateMapMode, UpdateMapZoom } from "./models/map.state.model";
import {produce} from "immer";
import { StateNames } from "../common/state-names.enum";

@State<MapStateModel>({
    name: StateNames.mapv11,
    defaults: {
        bounds: [[52.06371326609752, 5.188189790845172], [52.07140602038709, 5.197895250622423]],
        zoom: 18,
        center: [52.0697159, 5.1908749],
        mode: MapModes.Identify,
        layers: [...LAYER_SETTINGS as any],
        highlight: [],
        measureSettings: {
            advanced: false,
            snapping: true,
            multiple: false,
            angles: false,
            automatic: false
        }
    }
})
@Injectable()
export class MapState {
    @Selector([MapState])
    static bounds(state: MapStateModel) {
        return state.bounds;
    }

    @Selector([MapState.bounds])
    static xMin(bounds: LatLngBoundsLiteral) {
        return bounds[0][1];
    }

    @Selector([MapState.bounds])
    static xMax(bounds: LatLngBoundsLiteral) {
        return bounds[1][1];
    }

    @Selector([MapState.bounds])
    static yMin(bounds: LatLngBoundsLiteral) {
        return bounds[0][0];
    }

    @Selector([MapState.bounds])
    static yMax(bounds: LatLngBoundsLiteral) {
        return bounds[1][0];
    }

    @Selector([MapState])
    static zoom(state: MapStateModel) {
        return state.zoom;
    }

    @Selector([MapState])
    static center(state: MapStateModel) {
        return state.center;
    }

    @Selector([MapState.center])
    static centerXy(center: number[]) {
        return [center[1], center[0]];
    }

    @Selector([MapState])
    static mode(state: MapStateModel) {
        return state.mode;
    }

    @Selector([MapState])
    static layers(state: MapStateModel) {
        return state.layers;
    }

    @Selector([MapState])
    static measureSettings(state: MapStateModel) {
        return state.measureSettings;
    }

    @Selector([MapState])
    static highlight(state: MapStateModel) {
        return state.highlight;
    }

    static isLayerActive(name: string) {
        return createSelector(
            [MapState.layers],
            (layers: LayerSettings[]) => {
                const layer = layers.find(layer => layer.name === name);
                return layer?.active;
            }
        );
    }

    static getLayerByName(name: string) {
        return createSelector(
            [MapState.layers],
            (layers: LayerSettings[]) => {
                const layer = layers.find(layer => layer.name === name);
                return layer;
            }
        );
    }


    constructor() { }

    @Action(PatchMapState)
    patchMapState(ctx: StateContext<MapStateModel>, action: PatchMapState) {
        ctx.patchState(action.payload);
    }

    @Action(UpdateMapMode)
    setMapMode(ctx: StateContext<MapStateModel>, action: UpdateMapMode) {
        ctx.patchState({ mode: action.payload.mode });
    }

    @Action(UpdateMapCenter)
    updateMapCenter(ctx: StateContext<MapStateModel>, action: UpdateMapCenter) {
        ctx.patchState({ center: action.payload.center });
    }

    @Action(UpdateMapZoom)
    updateMapZoom(ctx: StateContext<MapStateModel>, action: UpdateMapZoom) {
        ctx.patchState({
            zoom: action.payload.zoom
        });
    }

    @Action(UpdateMapBounds)
    updateMapBounds(ctx: StateContext<MapStateModel>, action: UpdateMapBounds) {
        ctx.patchState({
            bounds: action.payload.bounds
        });
    }

    @Action(SetMeasureSettings)
    setMeasureSettings(ctx: StateContext<MapStateModel>, action: SetMeasureSettings) {
        const currentSettings = ctx.getState().measureSettings;
        ctx.patchState({
            measureSettings: {
                ...currentSettings,
                ...action.payload
            }
        });
    }

    @Action(EnableMapLayer)
    enableMapLayer(ctx: StateContext<MapStateModel>, action: EnableMapLayer) {
        const layerName = action.payload.name;

        const layerState = produce(ctx.getState().layers, draft => { 
            const layerToUpdate = draft.find(layer => layer.name === layerName);
            layerToUpdate.active = true;
        });

        ctx.patchState({ 
            layers: layerState
        });
    }

    @Action(DisableMapLayer)
    disableMapLayer(ctx: StateContext<MapStateModel>, action: DisableMapLayer) {
        const layerName = action.payload.name;

        const layerState = produce(ctx.getState().layers, draft => { 
            const layerToUpdate = draft.find(layer => layer.name === layerName);
            layerToUpdate.active = false;
        });

        ctx.patchState({ 
            layers: layerState
        });
    }

    @Action(ToggleMapLayer)
    toggleMapLayer(ctx: StateContext<MapStateModel>, action: ToggleMapLayer) {
        // Note: Use an immutability helper here.
        const layerName = action.payload.name;
        const layerState = produce(ctx.getState().layers, draft => {
            // Set new layer to active
            const layerToUpdate = draft.find(layer => layer.name === layerName);
            layerToUpdate.active = !layerToUpdate.active;
            // Handle different variations of a layer
            if (layerToUpdate.variations) {
                const layerVariation = action.payload.variation;
                layerToUpdate.variations.selected = layerVariation;

                if (layerToUpdate.canDisable === false) {
                    layerToUpdate.active = true; // Maybe this will cause bugs later on, cant be sure of this. @sdhoek
                }
            }
            // Handle basemaps
            if (layerToUpdate.type === 'basemap') {
                const baseMapsToDeactivate = draft.filter(layer => layer.type === 'basemap' && layer.name !== layerName);
                baseMapsToDeactivate.forEach(layer => layer.active = false);
            }
        });
        ctx.patchState({
            layers: layerState
        });
    }

    @Action(SetHighlight)
    setHighlight(ctx: StateContext<MapStateModel>, action: SetHighlight) {

        ctx.patchState({
            highlight: action.payload.highlight
        });
    }


}

