import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthCallbackComponent } from '@app/pages/auth-callback/auth-callback.component';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { CoreModule } from '../core.module';
import { AuthNewService } from '../services/auth-new.service';
import { GoogleAnalyticsService } from '../plugins/google-analytics/google-analytics.service';
import { NotificationService } from '../services/notification.service';
import { CreateUser, GetUser } from '../states/models/user.state.model';

@Injectable({
  providedIn: CoreModule
})
export class InitialLoginGuard {
  constructor(
    private store: Store,
    private ga: GoogleAnalyticsService,
    private authService: AuthNewService,
    private notificationService: NotificationService
  ) { }

  async canDeactivate(component: AuthCallbackComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    this.ga.event('login', {
      component: this.constructor.name
    });
    const userProfile = await this.authService.userProfile$.pipe(take(1)).toPromise();
    await this.store.dispatch(new CreateUser({ userAuth0Id: userProfile.sub })).toPromise();
    const snackbar = await this.notificationService.getSnackbar();
    snackbar.open(`Welkom, u bent ingelogd.`, 'OK', { panelClass: 'styled-snackbar', duration: 3000 });
    return true;
  }
}
