import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiParcel, StreetStatistics, KvK } from 'shared-types';
import { Verblijfsobject } from '../interfaces/verblijfsobject.model';
import { CoreModule } from '@app/core/core.module';
import { HttpClient } from '@angular/common/http';
import { AddressOsmInfo, EnergielabelExtraInfo, ExtraAddressInfo } from '../interfaces/extra-address-info.interface';
@Injectable({
  providedIn: CoreModule
})
export class AddressService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(private http: HttpClient) { }

  public get(id: string) {
    return this.http.get<Verblijfsobject>(`${this.apiLocation}/address/${id}`);
  }

  public getRelatedParcel(id: string) {
    return this.http.get<ApiParcel>(`${this.apiLocation}/address/${id}/parcel`);
  }

  public getExtraRelatedParcel(id: string) {
    return this.http.get<ApiParcel[]>(`${this.apiLocation}/address/${id}/related-parcel`);
  }

  public getRelatedAddresses(id: string) {
    return this.http.get<Verblijfsobject[]>(`${this.apiLocation}/address/${id}/related-addresses`);
  }

  public getRelatedAppartmentRight(id: string) {
    return this.http.get<string[]>(`${this.apiLocation}/address/${id}/appartment-right`);
  }

  public listNeighbours(id: string) {
    return this.http.get<string[]>(`${this.apiLocation}/address/${id}/appartment-right`);
  }

  public listAddressesInStreet(openbareruimteidentificatie: string) {
    return this.http.get<ApiParcel>(`${this.apiLocation}/street/${openbareruimteidentificatie}/address`);
  }

  public listBuildingsInBbox(bbox: { latMin: number, lngMin: number, latMax: number, lngMax: number }) {
    return this.http.get(`${this.apiLocation}/building`, {
      params: {
        latMin: `${bbox.latMin}`,
        latMax: `${bbox.latMax}`,
        lngMin: `${bbox.lngMin}`,
        lngMax: `${bbox.lngMax}`
      }
    });
  }

  public getStreetStatistics(nummeraanduiding: string) {
    return this.http.get<StreetStatistics>(`${this.apiLocation}/address/${nummeraanduiding}/street-statistics`);
  }

  public getEnergielabel(nummeraanduiding: string) {
    return this.http.get<EnergielabelExtraInfo>(`${this.apiLocation}/address/${nummeraanduiding}/energielabel`);
  }

  public getExtraInfo(nummeraanduiding: string) {
    return this.http.get<ExtraAddressInfo>(`${this.apiLocation}/address/${nummeraanduiding}/extra-info`);
  }

  public getOsmInfo(nummeraanduiding: string) {
    return this.http.get<AddressOsmInfo>(`${this.apiLocation}/address/${nummeraanduiding}/osm-info`);
  }

  public getKvkInfo(nummeraanduiding: string) {
    return this.http.get<KvK.Company[]>(`${this.apiLocation}/address/${nummeraanduiding}/kvk-info`);
  }

  public getNeighbors(nummeraanduiding: string) {
    return this.http.get<Verblijfsobject[]>(`${this.apiLocation}/address/${nummeraanduiding}/neighbor`);
  }

}
