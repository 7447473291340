import { ApplicationConfig, EnvironmentProviders, ErrorHandler, LOCALE_ID, Provider, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from 'environments/environment';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './core/states/app.state';
import { FilesState } from './core/states/files.state';
import { GpsState } from './core/states/gps.state';
import { MapState } from './core/states/map.state';
import { NotificationState } from './core/states/notification.state';
import { PaymentTermsState } from './core/states/payment-terms.state';
import { ProductsState } from './core/states/products.state';
import { SelectionState } from './core/states/selection.state';
import { ShoppingCartState } from './core/states/shopping-cart.state';
import { SubscriptionTypesState } from './core/states/subscription-type.state';
import { UserParcelState } from './core/states/user-parcels.state';
import { UserState } from './core/states/user.state';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from './core/core.module';
import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig, provideImgixLoader, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { withViewTransitions } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GeneralErrorHandler } from './core/handlers/general-error-handler';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { NotFoundInterceptor } from './core/interceptors/not-found.interceptor';
import { RequestCountInterceptor } from './core/interceptors/request-count.interceptor';
import { DsoInterceptor } from './core/interceptors/dso.interceptor';
import { provideExperimentalZonelessChangeDetection } from '@angular/core';
import { StateNames } from './core/common/state-names.enum';

registerLocaleData(localeNl, 'nl');

const providers: (EnvironmentProviders | Provider)[] = [
    provideRouter(routes, withViewTransitions({ skipInitialTransition: true }), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
    provideAnimationsAsync(),
    importProvidersFrom(
        NgxsStoragePluginModule.forRoot({
            keys: [StateNames.appv2, StateNames.shoppingCartv2, StateNames.mapv11],
        }),
    ),
    importProvidersFrom(NgxsModule.forRoot(
        [
            AppState,
            ProductsState,
            ShoppingCartState,
            UserState,
            SubscriptionTypesState,
            PaymentTermsState,
            MapState,
            GpsState,
            SelectionState,
            UserParcelState,
            FilesState,
            NotificationState,
        ],
        {
            selectorOptions: {
                injectContainerState: false,
                suppressErrors: false,
            },
            developmentMode: environment.production === false
        }),
    ),
    importProvidersFrom(ServiceWorkerModule.register('/ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:15000'
    })),
    importProvidersFrom(CoreModule),
    {
        provide: LOCALE_ID,
        useValue: 'nl'
    },
    {
        provide: ErrorHandler,
        useClass: GeneralErrorHandler,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestCountInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: NotFoundInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: DsoInterceptor,
        multi: true
    },
    // provideExperimentalZonelessChangeDetection()
];

if (environment.production === false) {
    providers.push(
        importProvidersFrom(NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: false,
            trace: true,
            traceLimit: 15
        })),
    );
}

if (environment.useImgix === true) {
    providers.push(
        provideImgixLoader(environment.imgixDomain),
        {
            provide: IMAGE_CONFIG,
            useValue: {
                placeholderResolution: 30
            }
        },
    );
} else {
    providers.push(
        {
            provide: IMAGE_LOADER,
            useValue: (cfg: ImageLoaderConfig) => {
                return cfg.src;
            }

        },
    );
}

export const appConfig: ApplicationConfig = {
    providers: providers
};

