import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AsyncPipe, DOCUMENT, NgStyle } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { GroupNames } from '@app/core/interfaces/group-names.enum';
import { AppService } from '@app/core/services/app.service';
import { AuthNewService } from '@app/core/services/auth-new.service';
import { ShoppingCartState } from '@app/core/states/shopping-cart.state';
import { Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AppState } from '@app/core/states/app.state';
import { SetBannerClose, SetLastRead } from '@app/core/states/models/app.state.model';
import dayjs from 'dayjs';
import { MenuComponent } from '../menu/menu.component';
import { SpinComponent } from '@app/shared/spin/spin.component';
import { DatHuisService } from '@app/core/services/dathuis.service';
import { AccountButtonComponent } from '../account-button/account-button.component';
import { MenuItem } from '../menu/menu.interface';
import { HeaderMenutrayComponent } from '../header-menutray/header-menutray.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    MenuComponent,
    NgStyle,
    AsyncPipe,
    SpinComponent,
    AccountButtonComponent,
    HeaderMenutrayComponent
  ],
})
export class HeaderComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public showItem: null | 'notifications' | 'menu' | 'mainDesktopNavMenu' | 'moreDesktopNavMenu' = null;
  public showNotificationBanner = true;
  public GroupNames = GroupNames;
  private previousScrollTop = 0;
  public headerDisplacement = 58;
  public bannerClose$ = this.store.select(AppState.bannerClose).pipe(
    map(bannerClose => {
      if (bannerClose === undefined) {
        return false;
      }

      const bannerPlacedAt = dayjs('2023-8-7').startOf('day').toDate().getTime();
      if (bannerClose < bannerPlacedAt) {
        return false;
      }

      return true;
    })
  );
  public itemsInCart$: Observable<number> = this.store.select(ShoppingCartState.cartItems).pipe(map(cart => cart.length));;
  public isAdmin$ = this.authNew.isInGroup$(GroupNames.admin);
  public isMobile$ = this.appService.mobileView$;

  public isPlus$ = this.authNew.isInGroup$(GroupNames.plus);
  public isPro$ = this.authNew.isInGroup$(GroupNames.pro);


  public mainDesktopNavMenu$ = combineLatest([this.isPlus$, this.isPro$]).pipe(
    startWith([false, false]),
    map(([isPlus, isPro]) => {

      const mainDesktopNavMenu: MenuItem[] = [
        {
          label: 'Percelen',
          subLabel: 'Bekijk percelen op de kaart',
          items: [],
          routerLink: '/',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-layout',
        },
        {
          label: 'Adressen',
          subLabel: 'Informatie over elk adres in Nederland',
          items: [],
          routerLink: '/adres',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-home',
        },
        {
          label: 'Bestemmingen',
          subLabel: 'Wat er wél en niet mag op een perceel',
          items: [],
          routerLink: '/omgevingswet',
          subscriptionIndicator: 'plus',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-file-text',
        },
        {
          label: 'Gebeurtenissen',
          subLabel: 'Blijf op de hoogte van lokale ontwikkelingen',
          items: [],
          routerLink: '/gebeurtenissen',
          subscriptionIndicator: 'plus',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-calendar'
        },
        {
          label: 'Geavanceerd zoeken',
          subLabel: 'Vind en exporteer percelen o.b.v. uw criteria',
          items: [],
          routerLink: this.getExportUrl(isPro),
          subscriptionIndicator: 'pro',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-filter'
        },
      ];
      return mainDesktopNavMenu
    })
  )

  public mobileMenuItems$ = combineLatest([this.isPlus$, this.isPro$]).pipe(
    startWith([false, false]),
    map(([isPlus, isPro]) => {
      return this.formatMobileMenu(isPlus, isPro);
    })
  );

  public moreDesktopNavMenu: MenuItem[] = [
    {
      header: 'Support',
      label: 'Handleiding',
      items: [],
      routerLink: '/handleiding',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book'
    },
    {
      label: 'Veelgestelde vragen',
      items: [],
      routerLink: '/veelgestelde-vragen',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-help-circle'
    },
    {
      label: 'Contact',
      items: [],
      routerLink: '/support',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-message-square',
      noBorder: true
    },
    {
      header: 'Kennisbank',
      label: 'Begrippen',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
      routerLink: '/begrippen',
      items: []
    },
    {
      label: 'Woonplaatsen',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
      routerLink: '/woonplaatsen',
      items: []
    },
    {
      label: 'Kadastrale gemeenten',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
      routerLink: '/kadastrale-gemeenten',
      items: []
    },
    {
      label: 'Gemeenten',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
      routerLink: '/gemeenten',
      items: []
    },
    {
      label: 'Wijken',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
      routerLink: '/wijken',
      items: []
    },
    {
      label: 'Buurten',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
      routerLink: '/buurten',
      items: [],
      noBorder: true
    },
    {
      header: 'Overig',
      label: 'Nieuws',
      items: [],
      routerLink: '/nieuws',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-mail'
    },
    {
      label: 'API',
      items: [],
      routerLink: '/upgrade/api',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-repeat'
    },
    {
      label: 'Maatwerk',
      items: [],
      routerLink: '/maatwerk',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-pen-tool'
    },
    {
      label: 'Over ons',
      items: [],
      routerLink: '/over-ons',
      icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-info',
      noBorder: true
    },
  ];


  constructor(
    private authNew: AuthNewService,
    private router: Router,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private appService: AppService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private dathuisService: DatHuisService,
  ) {

    // this.subscriptions.push(
    //   this.bannerClose$.subscribe(
    //     bannerClose => {
    //       if (bannerClose) { 
    //         this.renderer2.removeClass(this.document.body, 'banner-visible');
    //       }
    //       else {
    //         this.renderer2.addClass(this.document.body, 'banner-visible');
    //       }
    //     }
    //   )
    // )

  }
  public isAuthenticated$ = this.authNew.isAuthenticated$;

  public lastRead$ = this.store.select(AppState.lastRead).pipe(
    map(lastRead => {
      return lastRead === undefined || lastRead === null ? dayjs().subtract(5, 'year').toDate() : lastRead
    })
  );


  public outsideCheckout$ = this.router.events.pipe(
    map(e => {
      const url = this.router.url;
      if (url.includes('afrekenen')) {
        return false;
      } else {
        return true;
      }
    }),
    startWith(false),
  );

  public hideItems$ = combineLatest(this.dathuisService.enabled$, this.outsideCheckout$).pipe(
    map(([dahtuisenabled, outsideCheckout]) => {
      return dahtuisenabled || (outsideCheckout === false);
    })
  )


  ngOnInit() {
    // Run this scroll event listener outside angular to prevent running change detection for the whole application.
    this.document.defaultView.addEventListener('scroll', (event) => {
      this.hideHeaderOnMobile(event);
    });

    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.toggleShowItem(null);
        this.closeMenuAfterNavigation();
      })
    );
  }

  public toggleShowItem(item: typeof this.showItem) {
    if (this.showItem === item) {
      this.showItem = null;
    } else {
      this.showItem = item;
    }

    if (this.showItem === 'menu') {
      this.renderer2.addClass(this.document.body, 'disable-scroll');
    } else {
      this.renderer2.removeClass(this.document.body, 'disable-scroll');
    }
  }


  public login() {
    this.authNew.login(this.router.url.split('(')[0]);
  }

  public closeMenuAfterNavigation() {
    this.renderer2.removeClass(this.document.body, 'disable-scroll');
  }

  private clamp(val: number, min: number, max: number) {
    return val > max ? max : val < min ? min : val;
  }

  private hideHeaderOnMobile(event: any) {
    let currentScrollTop = Math.max(0, event.target.scrollingElement.scrollTop);

    if (this.appService.hasMinLaptopWidth()) {
      const requireRedraw = this.headerDisplacement !== 58;

      this.headerDisplacement = 58;
      this.previousScrollTop = 0;

      if (requireRedraw) {
        this.changeDetectorRef.detectChanges();
      }
      return;
    }

    // Prevents overscroll funniness when pulling down to refresh
    currentScrollTop = Math.max(0, event.target.scrollingElement.scrollTop);
    const headerDisplacement = this.headerDisplacement - (currentScrollTop - this.previousScrollTop);
    const clampedDisplacement = this.clamp(headerDisplacement, 0, 58);
    const requireRedraw = this.headerDisplacement !== clampedDisplacement;

    this.headerDisplacement = clampedDisplacement;
    this.previousScrollTop = currentScrollTop;
    // Manually trigger change detection just for this component


    if (requireRedraw) {
      this.changeDetectorRef.detectChanges();
    }
  }



  public close() {
    this.store.dispatch(new SetBannerClose());
  }

  public handleBanner() {
    // Do something here.
  }

  private formatMobileMenu(isPlus: boolean, isPro: boolean) {
    const menu: MenuItem[] = [

      {
        label: 'Percelen',
        subLabel: 'Bekijk percelen op de kaart',
        items: [],
        routerLink: '/',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-layout',
      },
      {
        label: 'Adressen',
        subLabel: 'Informatie over elk adres in Nederland',
        items: [],
        routerLink: '/adres',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-home',
      },
      {
        label: 'Bestemmingen',
        subLabel: 'Wat er wel én niet mag op een perceel',
        items: [],
        routerLink: '/omgevingswet',
        subscriptionIndicator: 'plus',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-file-text',
      },
      {
        label: 'Gebeurtenissen',
        subLabel: 'Blijf op de hoogte van lokale ontwikkelingen',
        items: [],
        routerLink: '/gebeurtenissen',
        subscriptionIndicator: 'plus',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-calendar'
      },
      {
        label: 'Geavanceerd zoeken',
        subLabel: 'Vind en exporteer percelen o.b.v. uw criteria',
        items: [],
        routerLink: this.getExportUrl(isPro),
        subscriptionIndicator: 'pro',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-filter'
      },
      {
        label: 'Webwinkel',
        items: [],
        routerLink: '/producten',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-shopping-cart'
      },
      {
        label: 'Upgrade',
        items: [],
        routerLink: '/upgrade',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-star',
        emphasize: true
      },
      {
        label: 'Meer',
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
        items: [
          {
            header: 'Support',
            label: 'Handleiding',
            items: [],
            routerLink: '/handleiding',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book'
          },
          {
            label: 'Veelgestelde vragen',
            items: [],
            routerLink: '/veelgestelde-vragen',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-help-circle'
          },
          {
            label: 'Contact',
            items: [],
            routerLink: '/support',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-message-square',
            noBorder: true
          },
          {
            header: 'Kennisbank',
            label: 'Begrippen',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
            routerLink: '/begrippen',
            items: []
          },
          {
            label: 'Woonplaatsen',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
            routerLink: '/woonplaatsen',
            items: []
          },
          {
            label: 'Kadastrale gemeenten',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
            routerLink: '/kadastrale-gemeenten',
            items: []
          },
          {
            label: 'Gemeenten',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
            routerLink: '/gemeenten',
            items: []
          },
          {
            label: 'Wijken',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
            routerLink: '/wijken',
            items: []
          },
          {
            label: 'Buurten',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-book-open',
            routerLink: '/buurten',
            items: [],
            noBorder: true
          },
          {
            header: 'Overig',
            label: 'Nieuws',
            items: [],
            routerLink: '/nieuws',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-mail'
          },
          {
            label: 'API',
            items: [],
            routerLink: '/upgrade/api',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-repeat'
          },
          {
            label: 'Maatwerk',
            items: [],
            routerLink: '/maatwerk',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-pen-tool'
          },
          {
            label: 'Over ons',
            items: [],
            routerLink: '/over-ons',
            icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-info',
            noBorder: true
          }
        ]
      },

    ];

    return menu;
  }

  private getExportUrl(isPro: boolean) {
    return isPro ? '/export' : '/upgrade/geavanceerd-zoeken';
  }

}
